import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRequest } from '../utils/api';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import loading_cat from '../assets/loading_cat.gif'; // Adjust the path as needed
import { FaBirthdayCake, FaWeight, FaTransgender, FaPalette, FaMicrochip, FaIdCard, FaSyringe, FaUser, FaEnvelope, FaPhone } from 'react-icons/fa';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';

const PetLostProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const petProfileId = searchParams.get('petProfileId');
  const [petData, setPetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [wellnessCategories, setWellnessCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [activeTab, setActiveTab] = useState('About');
  const [activeSubcategoryTab, setActiveSubcategoryTab] = useState('');
  const [showLostPetInfo, setShowLostPetInfo] = useState(false); // State to toggle display of lost pet info

  useEffect(() => {
    const fetchPetData = async () => {
      try {
        const petUrl = `${process.env.REACT_APP_HOSTNAME}/api/Pets/GetLostPetDetails?petProfile=${petProfileId}&publicId=&profileNeeded=`;
        const petDataResponse = await getRequest(petUrl);
        setPetData(petDataResponse.data);

        const wellnessUrl = `${process.env.REACT_APP_HOSTNAME}/api/PetWellness/GetPetWellnessCategories`;
        const wellnessDataResponse = await getRequest(wellnessUrl);
        setWellnessCategories(wellnessDataResponse.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPetData();
  }, [petProfileId]);

   if (loading) {
    return (
      <LoadingContainer>
        <img src={loading_cat} alt="Loading..." />
      </LoadingContainer>
    );
  }  
  if (error) return <p>Error: {error}</p>;

  const handleLostPetInfoClick = () => {
    setShowLostPetInfo(!showLostPetInfo); // Toggle showLostPetInfo state
  };

  async function handleCategoryClick (category) {
    setActiveTab(category.title);

    try {
      const petCategoryInfoURL = `${process.env.REACT_APP_HOSTNAME}/api/PetWellness/GetPetWellnessCategoryTypes?petProfile=${petData.petId}&publicId=&categoryId=${category.id}`;
     console.log(petCategoryInfoURL)
      const petCategoryInfo = await getRequest(petCategoryInfoURL);
      setSubcategories(petCategoryInfo.data);
    } catch (err) {
      setError(err.message);
    }

  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setSubcategories([]); // Empty the subCategories array when a tab is clicked
  };


  return (
    <ProfileContainer>
      <Header>
        <Link to="/adopt">
          <BackButton onClick={() => {navigate('/lost');}}>&larr; Back</BackButton>
        </Link>
        <LogoContainer>
          <Logo onClick={() => {navigate('/');}} src={logo} alt="Pawers Logo" />
        </LogoContainer>
      </Header>
      <Content>
        <PetDetails>
          <PetImage src={petData.petImage} alt={petData.petName} />
          <PetInfo>
            <h1>{petData.petName}</h1>
            <p>{`${petData.age} years old`}</p>
            <BreedText>{petData.petBreed}</BreedText> {/* Apply the styled component here */}
          </PetInfo>
          {petData.isLost && (
              <LostPetButton onClick={handleLostPetInfoClick}>Pet is Lost</LostPetButton>
            )}
        </PetDetails>
        {showLostPetInfo && petData.isLost && (
          <LostPetInfo>
            <h2>Lost Pet Details</h2>
            <p>{petData.lostPetDetails.description}</p>
            <p>Contact: {petData.lostPetDetails.contactPhoneNumber}</p>
            <p>Address: {petData.lostPetDetails.address}</p>
            <p>Lost Date: {petData.lostPetDetails.lostDate}</p>
            <p>Reward: {petData.lostPetDetails.reward}</p>
            <p>Notes: {petData.lostPetDetails.notes}</p>
          <MapContainer>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={{ lat: petData.lostPetDetails.latitude, lng: petData.lostPetDetails.longitude }}
                zoom={15}
              >
                <Marker position={{ lat: parseFloat(petData.lostPetDetails.latitude), lng: parseFloat(petData.lostPetDetails.longitude) }} />
              </GoogleMap>
            </LoadScript>
          </MapContainer>
          </LostPetInfo>
        )}
        <RowContainer>
        <Tabs>
        <Tab onClick={() => handleTabClick('About')} active={activeTab === 'About'}>About</Tab>
        <Tab onClick={() => handleTabClick('Skills')} active={activeTab === 'Skills'}>Skills</Tab>
      </Tabs>
      { !petData.isRecordsPrivate && (
  <WellnessContainer>
    {wellnessCategories.map(category => (
      <WellnessCategory
        key={category.id}
        onClick={() => handleCategoryClick(category)}
        active={activeTab === category.title}
      >
        {category.title}
      </WellnessCategory>
    ))}
  </WellnessContainer>
)}

        </RowContainer>
        <TabContent>
        {activeTab && (
        <div>
          <SubcategoryTabs>
            {subcategories.map(subcategory => (
              <SubcategoryTab
                key={subcategory.id}
                onClick={() => setActiveSubcategoryTab(subcategory.title)}
                active={activeSubcategoryTab === subcategory.title}
              >
                {subcategory.title}
              </SubcategoryTab>
            ))}
          </SubcategoryTabs>
          <RecordsContainer>
            {subcategories.map(subcategory => (
              activeSubcategoryTab === subcategory.title && (
                <div key={subcategory.id}>
                  {subcategory.records.map(record => (
                    <Record key={record.id}>
                      <p>Action Date: {new Date(record.actionDate).toLocaleDateString()}</p>
                      <p>Record Value: {record.recordValue} {record.unitOfMeasure}</p>
                      <p>Notes: {record.notes}</p>
                      {record.image && <RecordImage src={record.imagePath} alt="Record" />}
                    </Record>
                  ))}
                </div>
              )
            ))}
          </RecordsContainer>
        </div>
      )}
          {activeTab === 'About' && (
            <AboutSection>
              <InfoRow>
                <InfoIcon><FaBirthdayCake /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Birthdate:</InfoTitle>
                  <InfoValue>{new Date(petData.dob).toLocaleDateString()}</InfoValue>
                </InfoDetails>
                <InfoIcon><FaWeight /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Weight:</InfoTitle>
                  <InfoValue>{petData.weight}</InfoValue>
                </InfoDetails>
              </InfoRow>
              <InfoRow>
                <InfoIcon><FaTransgender /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Gender:</InfoTitle>
                  <InfoValue>{petData.petGender}</InfoValue>
                </InfoDetails>
                <InfoIcon><FaPalette /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Color:</InfoTitle>
                  <InfoValue><ColorCircle color={petData.color} /></InfoValue>
                </InfoDetails>
              </InfoRow>
              <InfoRow>
                <InfoIcon><FaMicrochip /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Microchip ID:</InfoTitle>
                  <InfoValue>{petData.microChipId}</InfoValue>
                </InfoDetails>
                <InfoIcon><FaIdCard /></InfoIcon>
                <InfoDetails>
          <InfoTitle>Public ID:</InfoTitle>
          <InfoValue>{petData.publicId || 'N/A'}</InfoValue>
        </InfoDetails>
              </InfoRow>
              <InfoRow>
                <InfoIcon><FaSyringe /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Neutered:</InfoTitle>
                  <InfoValue>{petData.isSterilized ? 'Yes' : 'No'}</InfoValue>
                </InfoDetails>
                <InfoIcon><FaSyringe /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Blood Type:</InfoTitle>
                  <InfoValue>{petData.bloodType}</InfoValue>
                </InfoDetails>
              </InfoRow>
              <InfoRow>
                <DescriptionTitle>Pet Owner Info</DescriptionTitle>
              </InfoRow>
              <InfoRow>
                <InfoIcon><FaUser /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Owner Username</InfoTitle>
                  <InfoValue>{petData.ownerName}</InfoValue>
                </InfoDetails>
              </InfoRow>
              <InfoRow>
                <InfoIcon><FaEnvelope /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Contact Email:</InfoTitle>
                  <InfoValue>{petData.ownerContactEmail}</InfoValue>
                </InfoDetails>
              </InfoRow>
              <InfoRow>
                <InfoIcon><FaPhone /></InfoIcon>
                <InfoDetails>
                  <InfoTitle>Phone Number:</InfoTitle>
                  <InfoValue>{petData.ownderContactPhone || 'N/A'}</InfoValue>
                </InfoDetails>
              </InfoRow>
              <InfoRow>
                <DescriptionTitle>About {petData.petName}</DescriptionTitle>
                </InfoRow>
                <InfoRow>
                <p>{petData.description}</p>
              </InfoRow>
            </AboutSection>
          )}
          {activeTab === 'Skills' && (
            <SkillsSection>
              {petData.petSkills.map(skill => (
                <Skill key={skill.id}>
                  <SkillImage src={skill.image} alt={skill.title} />
                  <SkillTitle>{skill.title}</SkillTitle>
                </Skill>
              ))}
            </SkillsSection>
          )}
          
        </TabContent>
      </Content>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  background-color: #f5f7fc; 
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 50px;
  &:hover {
    text-decoration: underline;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
`;

const BreedText = styled.p`
  color: #5951fc;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
`;

const PetDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PetImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const PetInfo = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const LostPetButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #ff3333;
  }
`;

const LostPetInfo = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
`;



const MapContainer = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 20px;
`;

const ColorCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color || 'transparent'};
  display: inline-block;
  margin-left: 5px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
  width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background-color: ${(props) => (props.active ? '#007bff' : '#e0e0e0')};
  color: ${(props) => (props.active ? '#ffffff' : '#000000')};
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => (props.active ? '#0056b3' : '#d3d3d3')};
  }
`;

const WellnessContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const WellnessCategory = styled.button`
  background-color: ${(props) => (props.active ? '#007bff' : '#e0e0e0')};
  color: ${(props) => (props.active ? '#ffffff' : '#000000')};
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => (props.active ? '#0056b3' : '#d3d3d3')};
  }
`;

const TabContent = styled.div`
  width: 100%;
`;

const AboutSection = styled.div`
  width: 100%;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const InfoIcon = styled.div`
  font-size: 1.5em;
  margin-right: 10px;
  color: #373f95;
  border: 2px solid #373f95;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

const InfoDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const InfoTitle = styled.p`
  font-size: 0.8em;
  color: #666;
  margin-bottom: 5px;
`;

const InfoValue = styled.p`
  font-size: 1em;
`;

const SkillsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 20px;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SkillImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const SkillTitle = styled.p`
  margin-top: 5px;
  text-align: center;
`;

const DescriptionTitle = styled.h2`
  font-size: 1.2em;
  margin-top: 10px;
`;

const SubcategoryTabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
`;

const SubcategoryTab = styled.button`
  background: ${props => props.active ? '#FF5722' : '#ffe0b2'};
  color: ${props => props.active ? 'white' : 'black'};
  border: 1px solid ${props => props.active ? '#FF5722' : '#ffe0b2'};
  padding: 10px 15px;
  margin: 5px;
  border-radius: 15px;
  cursor: pointer;
  transition: background 0.3s;
  font-weight: ${props => props.active ? '600' : 'normal'};
  &:hover {
    background: ${props => props.active ? '#e64a19' : '#ffcc80'};
  }
`;

const RecordsContainer = styled.div`
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  margin: 20px 0;
`;

const Record = styled.div`
  background: white;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  p {
    margin: 5px 0;
    font-weight: 600;
  }
`;

const RecordImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
  margin-top: 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
`;

export default PetLostProfile;
